.formDIV {
  margin-top: 4%;
  margin-left: 35%;
  margin-right: 35%;
  background-color: #f2f2f2;
  height: auto;
  box-shadow: 0 1px 14px #f2f2f2;
}

.header {
  background-color: white;
  height: 120px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.login-card-div {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.header-img-div {
  width: 80%;
  justify-content: space-between;
  display: flex;
}

.titleLogo {
  object-fit: contain;
  width: 250px;
  height: 100px;
}

.contactButtonREG {
  margin-top: 5%;
}

.backToHome {
  margin-top: 5%;
}

.contactButtonREG:focus {
  outline: none;
}

.secondLogo {
  height: 140px;
}

::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}

.formDataFiled {
  margin-left: 5%;
  margin-right: 5%;
}

.h3Data {
  padding-top: 2%;
  padding-bottom: 2%;
  color: orange;
}

.emailImage {
  height: 25px;
}

.termconDiv {
  height: 600px;
  width: 80%;
  background-color: white;

  overflow-y: scroll;

  margin-left: 10%;
}

.captchaImage {
  height: 50%;
  width: 90%;
}

.catchaCode {
  position: absolute;
  bottom: 50%;
  left: 30%;
}

.reFressCaptcha {
  cursor: pointer;
  height: 40%;
  width: 90%;
}

.footerBar {
  height: 100px;
  background-color: #f2f2f2;
}

.dataDIV {
  margin-top: 15%;
}

/* For I pad Screen */

@media only screen and (max-width: 1200px) {
  .formDIV {
    height: 150%;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }

  .titleLogo {
    width: 250px;
    height: 100px;
    object-fit: contain;
  }

  .secondLogo {
    width: 100px;
    height: 100px;
  }

  .contactButtonREG {
    margin-top: 0%;
  }

  .secodnDivForm {
    margin-top: 5%;
  }

  .reFressCaptcha {
    height: 40px;
    width: 50px;
  }

  .footerBar {
    height: 200px;
    background-color: #f2f2f2;
  }

  .dataDIV {
    margin-top: 2%;
  }
}

/* Media query for 370 */
@media only screen and (max-width: 500px) {
  .formDIV {
    height: auto;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }

  .titleLogo {
    width: 100px;
    height: 50px;
    object-fit: contain;
  }

  .secondLogo {
    width: 100px;
    height: 100px;
  }

  .contactButtonREG {
    margin-top: 0%;
  }

  .secodnDivForm {
    margin-top: 5%;
  }

  .reFressCaptcha {
    height: 40px;
    width: 50px;
  }

  .footerBar {
    height: auto;
    background-color: #f2f2f2;
  }

  .dataDIV {
    margin-top: 2%;
  }
}
