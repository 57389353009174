.allDiveHere{

    margin-top: 20%;
    height: 100px;
    background-color: rgb(240, 241, 181);
    border-radius: 10px;

    box-shadow: 16px 16px 20px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
}


.homeScreeAll{


    background-color: #F7F9F9;
    height: 680px;
}

.downLoadHRIS{

    margin-left:  160px;
}

.LinkTag{
   
    margin-top: 10px;
}


.everyThingPl{

    margin-top: 5%;

}


.divLable{

    background-color: white;
    width: 600px;
    height: 550px;
    margin-left: 28%;
    margin-top: 5%;
    box-shadow: 0 1px 14px #f2f2f2;
}


.tableClass{
 
    padding : 100px;
    margin-left: 50px;
  
}

.lableTD{

    width: 180px;
    text-align: left;
    font-size: 120%;
}

.lableTD2{

    width: 250px;
    text-align: left;
    font-size: 120%;
}

.buttonTag{

    margin-top: 2%;
    align-items: left;
}

.hrTag{

    background-color :#000080;
    height: 5px;

}

.servicesTable{

    background-color: rgb(240, 241, 181);
    border: 2px solid rebeccapurple;
    margin-left: 5%;
    margin-right: 5%;
    box-shadow: 0 1px 14px #f2f2f2;
}

.servicesTable tr td
{
    border: 2px solid rebeccapurple;
    font-size: 120%;
}


.serviceImage{

    height: 30px;
}


.fontSizeInC{

    font-size: 160%;
}

.invoiceTable{

    background-color: antiquewhite;
    width: 700px;
    height: 600px;
    margin-left: 300px;
    margin-top: 2%;
    box-shadow: 0 1px 14px #f2f2f2;
}

.invoiceLogo{

    height: 100px;
    border-radius: 50%;
    align-items: left;
    margin-bottom: 2%;
   
}






.img{

    margin-left: 30%;
    margin-right: 30%;
    height: 200px;
    width: 200px;
    margin-top: 7%;
}

.UploadButton{

    margin-left: 20%;
    margin-right: 20%;
    margin-top: 10%;
}

.downloadFiles{

    background-color: white;
   
    height: 650px;
    margin-top: 1%;
    box-shadow: 0 1px 14px #f2f2f2;
    overflow-y: scroll;
}


.hrisTable{

    background-color: white;
    margin-left: 30%;
    margin-right: 30%;
    height: 650px;
    margin-top: 1%;
    box-shadow: 0 1px 14px #f2f2f2;
    
}

.hrisImage{

    height: 50%;
}

.hrisDownloadButt{

    width: 90%;
    background-color :lightblue;
    border-radius: 20px;
    margin-top: 3%;
    border: none;
    outline: none;
    box-shadow: 16px 16px 20px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
}

.hrisDownloadButt:focus{

    outline: none;
}

.downLoadFile{

    height: 20px;
 
}


.homeDiv{

    width: 100%;
    background-color: white;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 1%;
    box-shadow: 0 1px 14px #f2f2f2;
    height: 660px;
}


.HomeScreen{

    width: 100%;
}

.tableCSSPrice{

    background-color:cornsilk;
    border: solid 1px black;
    width: 100%;
    margin-left: 1%;
    overflow-x: scroll;
    overflow-y: scroll;
}

.tableHeadColumn{

    width: 35%;
}

.deffPlans{

    width: 13%;
}


.checkTermCom{

    margin-left: 20%;
}

.termComditionButton{

    margin-left: 40%;
}

.navLinkSize{

    width: 130px;
}

.imageSmall{

    width: 5%;
}




.cardColorHome{

    background-color: white;
    box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    height: 110%;
}


.marginTopTo{

    margin-top: 2%;
}


.buttonCheck{

width: 50%;
margin-left: 25%;

}

.classButton{

    margin-top: 10%;
}


.headingPlan{

    margin-top: 10%;
}


/*For I=Pad*/


@media only screen and (max-width:1200px){ 


    .homeScreeAll{

        height: auto;
    }

    .cardColorHome{

      margin-top: 5%;
      margin-bottom: 5%;
       height : auto;
    }
  

    .navLinkSize{

        width: 0px;
    }

    
    .tableClass{
 
        padding : 0px;
        margin-left: 2%;
      
    }

    .lableTD{

        font-size: 90%;
    }



    .lableTD2{
        font-size: 90%;
    }
    
    .divLable{
        width: 100%;
        margin: 0%;
        margin-top: 10%;
        margin-bottom: 5%;
        
    
    }

    
    .servicesTable{
        margin: 0%;
       
    }




    .hrisTable{

        margin: 0%;
        margin-bottom: 5%;

    }


    .downloadFiles{
       
        height: 650px;
        margin-top: 10%;
        margin-bottom: 5%;
        overflow-y: scroll;
    }

    
    .UploadButton{
    
        margin: 7%;

    }

    .fileType{
        margin: 7%;
    }
    
    

}


@media only screen and (max-width:600px){ 

    .downLoadHRIS{

        margin-left:  30px;
    }
    
    .homeScreeAll{

        height: auto;
    }

    .cardColorHome{

      margin-top: 10%;
      margin-bottom: 5%;
       height : auto;
    }
  

    .navLinkSize{

        width: 0px;
    }

    
    .tableClass{
 
        padding : 0px;
        margin-left: 2%;
      
    }

    .lableTD{

        font-size: 90%;
    }



    .lableTD2{
        font-size: 90%;
    }
    
    .divLable{
        width: 100%;
        margin: 0%;
        margin-top: 10%;
        margin-bottom: 5%;
        
    
    }

    
    .servicesTable{
        margin: 0%;
       
    }




    .hrisTable{

        margin: 0%;
        margin-bottom: 5%;

    }


    .downloadFiles{
       
        height: 650px;
        margin-top: 10%;
        margin-bottom: 5%;
        overflow-y: scroll;
    }

    
    .UploadButton{
    
        margin: 7%;

    }

    .fileType{
        margin: 7%;
    }
    
    

}